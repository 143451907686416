import i18next from 'i18next';
import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import { useDoctor } from 'src/hooks/use-doctor';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'en',
    label: 'English (US)',
    icon: '/assets/icons/ic_flag_en.svg',
  },
  {
    value: 'en-GB',
    label: 'English (GB)',
    icon: '/assets/icons/ic_flag_gb.svg',
  },
  {
    value: 'hr',
    label: 'Croatian',
    icon: '/assets/icons/ic_flag_hr.svg',
  },
  {
    value: 'pt-PT',
    label: 'Portuguese',
    icon: '/assets/icons/ic_flag_pt.svg',
  },
  // {
  //   value: 'es',
  //   label: 'Spanish',
  //   icon: '/assets/icons/ic_flag_es.svg',
  // },
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const cookiedLang = LANGS.findIndex((lang) => lang.value === i18next.language?.toLowerCase());

  const [open, setOpen] = useState(null);
  const [langId, setLangId] = useState(cookiedLang);

  const { lang, setLang } = useDoctor();

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleClick = (index) => {
    setLangId(index);
    setLang(LANGS[index].value);
    setOpen(null);
  };

  useEffect(() => {
    setLangId(LANGS.findIndex((langs) => langs.value === lang));
  }, [lang]);

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: 'action.selected',
          }),
        }}
      >
        <img src={LANGS?.[langId]?.icon} alt={LANGS?.[langId]?.label} />
      </IconButton>

      <Popover
        open={!!open}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1,
            ml: 0.75,
            width: 180,
          },
        }}
      >
        {LANGS.map((option, index) => (
          <MenuItem
            key={option.value}
            selected={option.value === LANGS[langId]?.value}
            onClick={() => handleClick(index)}
            sx={{ typography: 'body2', py: 1 }}
          >
            <Box component="img" alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

            {option.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
}
