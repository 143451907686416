import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './i18n';
import './sentry';
import App from './app';
import { AuthProvider } from './context/AuthProvider';
import { DoctorProvider } from './context/DoctorProvider';

// ----------------------------------------------------------------------

// bokeh throws a lot of warnings in console for no reason, so block them in production
if (import.meta.env.PROD) {
  const consoleWarn = console.warn;
  console.warn = (message, ...args) => {
    if (message !== '[bokeh]') consoleWarn(message, ...args);
  };
}

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_GTM,
};
TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Suspense>
        <AuthProvider>
          <DoctorProvider>
            <App />
          </DoctorProvider>
        </AuthProvider>
      </Suspense>
    </BrowserRouter>
  </HelmetProvider>
);
