import { useState } from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import { Container } from '@mui/material';

import Logo from 'src/components/logo';

import Nav from './nav';
import Main from './main';
import Header from './header';
import LanguagePopover from './common/language-popover';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const [openNav, setOpenNav] = useState(false);

  return (
    <>
      <Header onOpenNav={() => setOpenNav(true)} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>{children}</Main>
      </Box>
    </>
  );
}

export function NoNavLayout({ children }) {
  return (
    <Container sx={{ p: 0 }}>
      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: { xs: 1, md: 2 },
          background: 'rgba(255,255,255,0.5)',
          backdropFilter: 'blur(5px)',
          zIndex: 1000, // Ensure it stays on top of other content
        }}
      >
        <Logo
          sx={{
            ml: { xs: 1, md: 2 },
          }}
        />
        <Box
          sx={{
            mr: { xs: 1, md: 2 },
          }}
        >
          <LanguagePopover />
        </Box>
      </Box>

      <Container sx={{ height: '100%', p: 0 }}>{children}</Container>
    </Container>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

NoNavLayout.propTypes = {
  children: PropTypes.node,
};
