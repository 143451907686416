import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { LinearProgress } from '@mui/material';

import useAxios from 'src/hooks/use-axios';
import { useAuth } from 'src/hooks/use-auth';
import { useDoctor } from 'src/hooks/use-doctor';

function RequireAuth({ element }) {
  const { id } = element.props;

  const { doctor, setDoctor } = useDoctor();
  const location = useLocation();
  const { auth } = useAuth();
  const api = useAxios();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    api
      .get('/v1/users/profile/')
      .then((res) => {
        setDoctor({ ...res.data });
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <LinearProgress sx={{ mx: 15, my: 'auto' }} />;

  const hasAccess = doctor?.access.includes(id);

  const redirectPath =
    location.pathname !== '/'
      ? `?next=${encodeURIComponent(location.pathname + location.search)}`
      : '';

  if (!auth) {
    return <Navigate to={`/login/${redirectPath}`} state={{ from: location }} replace />;
  }

  if (doctor?.last_name?.toLowerCase() === 'demo') {
    const demo_user = "/patients/7242"
    if (location.pathname !== demo_user)
    return <Navigate to={demo_user} state={{ from: location }} replace />;
  }

  if (!hasAccess) {
    if (doctor?.access.includes('screening'))
      return <Navigate to="/screening" state={{ from: location }} replace />;
    if (doctor?.access.includes('mcs'))
      return <Navigate to="/mcs" state={{ from: location }} replace />;

    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return element;
}

export default RequireAuth;

RequireAuth.propTypes = {
  element: PropTypes.element,
};
