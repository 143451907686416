import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import { useState, createContext } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const cookies = new Cookies();
  const defaultAuth = cookies.get('megi-authorization');

  const [auth, setAuth] = useState(defaultAuth);

  const handleSetAuth = (token) => {
    if (!token) {
      cookies.remove('megi-authorization', { path: '/' });
      setAuth(null);
      return;
    }
    // set age to 1 year
    cookies.set('megi-authorization', token, { path: '/', maxAge: 31556952 });
    setAuth(token);
  };

  return <AuthContext.Provider value={{ auth, setAuth: handleSetAuth }}>{children}</AuthContext.Provider>;
};

export default AuthContext;

AuthProvider.propTypes = {
  children: PropTypes.any,
};
